<template>
  <div class="markdown-render space-y-2 px-4 py-3">
    <h2 v-if="content.title">
      {{ content.title }}
    </h2>
    <div
      v-if="content.content"
      v-html="content.content"
    />
  </div>
</template>
<script>
export default {
  friendlyName: 'Text Block',

  props: {
    item: {
      type: Object,
      required: true
    }
  },

  computed: {
    content () {
      return this.item.content
    }
  }
}
</script>
